import React from "react";
import serviceone from "../../../static/images/serviceone.png";
import Evolution from "../../../static/images/empbenefit.jpg";

export default function sectionone() {
  return (
    <div>
      <section
        class="lg:py-16 lg:px-20 bg-white overflow-hidden"
        style={{ fontFamily: "poppins" }}
      >
        <div class="container px-4 mx-auto">
          {/* <div class="flex flex-wrap xl:items-center -m-8">
            <div class="w-full md:w-1/2 p-8">
              <img
                class="pt-12 lg:pt-4 lg:mx-auto transform hover:translate-y-3 transition ease-in-out lg:w-3/4 lg:h-3/4 duration-1000"
                src={EmployeeBenefit}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-xl">
                <p class="mb-2 text-sm text-indigo-900 font-semibold uppercase tracking-px">
                  &#x1F44B; Our Services
                </p>
                <h2 class=" mb-16 text-2xl md:text-4xl font-bold text-indigo-800 font-heading tracking-px-n leading-tight">
                Employee Benefit Consulting
                </h2>
                <div class="flex flex-wrap mb-5 pb-10 -m-8">
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Valuation
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            {" "}
                            Valuation of Employee benefit schemes for
                            organizations
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Standard Reports
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            {" "}
                            Reports based on International Accounting Standards
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Gap Analysis
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            {" "}
                            Policy Structuring and Healthcare risk management
                            Recommendations
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Support
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            HR teams for drafting of employee benefit strategy
                            and budget
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="md:inline-block">
            <button class="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Get Started Now</button>
          </div>
              </div>
            </div>
          </div> */}

          <section class=" py-12 lg:px-8">
            <div class="max-w-7xl mx-auto">
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                {/* <!-- Text Section --> */}
                <div class="bg-white rounded-lg overflow-hidden ">
                  <div class="p-6">
                    <h2 class="text-base font-semibold leading-7 text-indigo-600">
                      Our Services
                    </h2>
                    <h3 class="mt-2 text-3xl font-bold tracking-tight text-blue-900 sm:text-4xl">
                      Employee Benefits Evaluation
                    </h3>
                    <p class="lg:text-lg leading-8 text-gray-600 mt-5">
                      RJ Actuaries & Consultants helps businesses evaluate and
                      review their existing employee medical benefits, providing
                      gap assessments, policy structuring, and healthcare risk
                      management. The company supports HR teams in drafting
                      employee benefit strategies and budget management, and
                      offers services related to risk management documentation
                      and framework set-up.
                    </p>
                    <ul class="list-disc ml-6 mt-5">
                      <li>Designing</li>
                      <li>Managing</li>
                      <li>Evaluating</li>
                    </ul>
                  </div>
                </div>

                {/* <!-- Image Section --> */}
                <div class="bg-white rounded-lg overflow-hidden">
                  <img
                    class="w-full h-auto object-cover"
                    src={Evolution}
                    alt="Image"
                  />
                </div>
              </div>
            </div>

            <div class="max-w-7xl mx-auto">
              <div class="grid grid-cols-1">
                {/* <!-- Text Section --> */}
                <div class="bg-white rounded-lg overflow-hidden shadow-lg p-6">
                  <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Employee Benefit Plans
                  </h3>
                  <p class="lg:text-lg leading-8 text-gray-600 mt-5">
                    We work with employers to develop and implement benefit
                    plans that meet the needs of their employees while also
                    managing costs. This involves analyzing data on:
                  </p>
                  <ul class="list-disc ml-6 mt-5">
                    <li>Employee demographics</li>
                    <li>Health status</li>
                    <li>Utilization patterns</li>
                    <li>Need and Gap analysis</li>
                  </ul>
                  <p class="lg:text-lg leading-8 text-gray-600 mt-5">
                    to determine the appropriate level of coverage and
                    cost-sharing arrangements. RJ Actuaries & Consultants also
                    provide Actuarial Valuation for Employee Benefits Liability
                    as per various Accounting Standards including IAS 19
                    (Revised 2011), AS15, IndAS 19, USGAAP (ASC 715) & other
                    accounting standards.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
